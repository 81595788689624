const markdown = `
## 2.9.4 [27/1/25]

- Add button above soundboard to display a search bar.
- Improve performance.

## 2.9.3 [31/10/24]

- Don't show coloured background behind text on soundpad unless as background image is selected. Text no longer bold. Text 10% larger.

## 2.9.2 [27/10/24]

- Fix bug causing playback to not resume after leaving and re-entering the app while device is on silent mode.

## 2.9.1 [25/10/24]

- Allow playback while device is on silent mode (iOS 17+).

## 2.9.0 [24/10/24]

- You can now select an image to show on the sound pad. Find the new option in the pad settings window.
- Fixed volume indicator not updating live.
- Minor stylistic changes to soundboard page.

## 2.8.0 [21/10/24]

- Added ability to reorder soundboards in soundboards management.
- Add volume slider on soundboard playback page.
- A banner is now shown on launch to remind the user to take their device off silent mode. There is an option to disable this on the settings page.
- Sound files are now sorted by filename.
- Add ability to clone soundboards from soundboard management page.
- Change background of app to match web.
- Minor UI changes in soundboard playback and soundboard management views.

## 2.7.0 [15/9/2024]

- Fix signing in after session expired.
- Target higher Android API version.
- Change design of UI buttons.
- When opening settings page, test user's cloud account token.
- Show warning icon when sound file missing.

## 2.6.0 [25/4/2024]

- Sounds must now be dragged by their progress bar to reposition them.
- There is now an indicator showing where you are about to drop a sound.
- The soundboard is now easy to scroll again without moving sounds instead.
- Revert Android app to security settings from 2.4.1 to restore data that was lost in 2.5.0. The recently released online features for Android will be temporarily unavailable due to this but they will be restored in coming weeks.
- Minor UI tweaks to edit sound settings modal.
- Defend against an unhandled error (when pad is not found in global state).

## 2.5.2 [14/4/2024]

- Show loading indicator on pad when sound is loading.
- Improve appearance of device buttons on settings page.
- Fix app sounds becoming unresponsive after resuming to foreground on iOS.
- Performance optimization: don't render edit pad dialog unless open.

## 2.5.1 [8/4/2024]

- Link to US version of MacOS app store.
- Improve Android icon and splash screen.
- Fix text in mobile web import modal and add default pad color when importing web soundboard.
- Fix full screen modal layouts on iOS.
- Fix change of loop option not immediately being reflected.

## 2.5.0 [7/4/2024]

- Add web integration support, allowing syncing of soundboards and browsing of Voicy sound library.
- New view for soundboards list.

## 2.4.1 [29/12/2023]

- Fix bug dragging and dropping sounds on Android. Previously, text was being selected instead.

## 2.4.0 [10/7/2023]

- Add loading screen while hydrating.
- Fix pause all sounds not working for sounds with fade out.
- Now load sounds one at a time by default. Add option in setting page to load all at once.

## 2.3.3 [13/4/2023]

- Fix sounds not playing after going from background to foreground on iOS.
- Soundboards list is no longer searchable to prevent keyboard popping up.
- Warm up iOS audio player on launch to avoid any delay in playing.
- Prevent scrolling of app container in iOS.

## 2.3.2 [3/4/2023]

- Version bump framework.

## 2.3.1 [2/4/2023]

- Improve help page wording and icons.
- Un-focus soundboard picker text field on change.

## 2.3.0 [30/3/2023]

- Support dragging and dropping sounds.

## 2.2.4 [26/2/2023]

- Fixed memory leak caused by opening waveforms.
- Add buttons to switch between soundboards easier. Hide soundboard switching buttons/dropdown when there is only one soundboard.
- Add links to other platforms (Android, not allowed on iOS due to monopolistic policies).

## 2.2.3 [5/1/2023]

- Add screen always awake.
- Add Sentry monitoring.
- Increase minimum ios version to 13.

## 2.2.2 [20/9/2022]

- Add support for M4A.
- Add support for split-screen on iPad.
- Add links to other devices and changelog in help section.
- Add Sentry error reporting.
- Only hydrate edit pad modal when open.

## 2.2.1 [13/4/2022]

- On first launch, warn users that certain devices can't be in silent mode.

## 2.2.0 [5/4/2022]

- Add text fields for start/end picker and fade sliders.
- Fade-out-on-pause is now triggered when pause is due to pausing all sounds or soundboard solo.
- Looping audio will no longer fail to pause when a "fade-out-on-pause" is triggered close to the end (currently requires custom end time).
- Don't repeatedly fade-in-on-play when looping (currently requires custom end time).
- Fix multi-shot audio not looping properly.
- Smooth out volume levels when looping.
- Fix fade-in-on-play not working properly first time with custom start time.

## 2.1.0 [31/3/2022]

- It is now possible to configure a sound fade-out on pause, and fade-in on play. An auto-fade-in option is also added to correspond to the existing auto-fade-out feature.
- Add clear button to edit pad modal.
- Volume for sound updates without hitting "save" on modal.
- Performance optimizations.
- It is now possible to clear the track for a pad without resetting the entire pad.
- Pad's current volume is now shown beneath progress bar. This factors in master volume, soundboard volume, pad volume, duck effect, and any fades.

## 2.0.1

- Fix link to app store review.

## 2.0.0 [28/3/2022]

- This is the first major reworking of Podcast Soundboard. It primarily features a significant visual improvement over the last version to match the new web version.
`

export default markdown
